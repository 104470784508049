import * as React from 'react';
import { Link } from "gatsby"
import { 
	Box,
	Grid,
	IconButton,
	Typography,
} from '@mui/material';
import LogoDowin from "images/logodowin.png";
import { 
	LinkedIn,
} from '@mui/icons-material';
import PapFooter from 'components/pap-footer.js';
import MediaQuery from 'react-responsive';
import AdriPic from 'images/AdrianR.png';
import AlvaroPic from 'images/AlvaroR.png';
import DavidPic from 'images/DavidR.png';
import SamuPic from 'images/SamuR.png';
import SaraPic from 'images/SaraR.png';
import ElenaPic from 'images/ElenaR.png';
import JulianPic from 'images/JulianR.png';

const pageStyles = {
	fontFamily: 'Quicksand',
};
const topBar = {
	height: '56px',
	marginTop: '0px',
	paddingLeft: '16px',
	marginBottom: '16px',
}
const imageContainer = {
	height: '36.7px',
}
const headerMargin = {
	marginBottom: '20px',
	marginLeft: '16px',
	display: 'inline-block',
	verticalAlign: 'middle',
}
const stepsTitle = {
	fontFamily: 'Quicksand',
	fontSize: '24px',
	lineHeight: '24px',
	fontWeight: '700',
}
const stepsText = {
	fontFamily: 'Quicksand',
	fontSize: '20px',
	lineHeight: '25px',
	fontWeight: '400',
}
const stepsContainer = {
	paddingTop: '74px',
	paddingBottom: '74px',
}
const verticalPadding40 = {
	paddingTop: '40px',
	paddingBottom: '40px',
}
const lightBlue = {
	backgroundColor: '#C6DCE8',
}
const lightGray = {
	backgroundColor: '#F1F1F1',
}
const white = {
	backgroundColor: '#FFFFFF',
}
const titleR400 = {
	fontFamily: 'Quicksand',
	fontSize: '28px',
	lineHeight: '30px',
	fontWeight: "400",
}
const titleR700 = {
	fontFamily: 'Quicksand',
	fontSize: '28px',
	lineHeight: '30px',
	fontWeight: "700",
}
const profileContainer = {
	maxWidth: '100%',
	maxHeight: '100%',
}


export default function AboutUs() {
	const handleMediaQueryChange = (matches) => {
		return matches;
	}

	return (
		<Grid container style={pageStyles} rowSpacing={0} columnSpacing={2}>
			<Grid item xs={12}>
				<Box>
					<Grid container spacing={2} style={topBar}>
						<Grid item xs={12}>
							<Link to="/">
								<img
									alt="dowin Logo"
									style={imageContainer}
									src={LogoDowin}
								/>
							</Link>
							<Box style={headerMargin}>
								<Typography fontFamily={"Quicksand"} display={"inline"} fontWeight="700" fontSize={24}>Nosotros</Typography>
							</Box>
						</Grid>
					</Grid>
					<Grid style={verticalPadding40} justifyContent={'center'} textAlign={"center"} container spacing={2}>
						<Grid item xs={10}>
								<Typography variant={"h2"} style={titleR400} display={"inline"}>El equipo de </Typography>
								<Typography variant={"h2"} style={titleR700} display={"inline"}>dowin</Typography>
								<Typography variant={"h2"} style={titleR400} display={"inline"}> está formado por ocho personas y la ayuda externa de una asesoría fiscal, contable y laboral, además de un despacho jurídico.</Typography>
						</Grid>
					</Grid>
				</Box>
			</Grid>
			<Grid item xs={12}>
				<Box style={lightBlue}>
					<Grid style={stepsContainer} container spacing={2}>
						<Grid item xs={12} md={6} style={{ paddingLeft: '36px', paddingRight: '36px'}}>
							<Typography style={stepsTitle}>Adrián Muñoz García</Typography>
							<Typography style={stepsText}>PhD candidate Behavioral Economics, Behavioral Research Analyst en Behave4 y co-fundador de Premium & Premium.</Typography>
							<br/>
							<Typography style={stepsText}>Puedes seguirme en:
								<a href='https://www.linkedin.com/in/adrian-munoz-garcia/'>
									<IconButton aria-label="linkedin" size="large">
										<LinkedIn fontSize="inherit" style={{fill: '#000'}}/>
									</IconButton>
								</a>
							</Typography>
						</Grid>
						<Grid item xs={12} md={6} textAlign={'center'} style={{ paddingLeft: '36px', paddingRight: '36px', maxHeight: '350px'}}>
							<img
								alt="Adrián Muñoz profile picture"
								style={profileContainer}
								src={AdriPic}
							/>
						</Grid>
					</Grid>
				</Box>
			</Grid>
			<Grid item xs={12}>
				<Box style={lightGray}>
					<Grid style={stepsContainer} container spacing={2}>
						<Grid item xs={12} md={6} textAlign={'center'} style={{ paddingLeft: '36px', paddingRight: '36px', maxHeight: '350px'}}>
							<img
								alt="Sara Rojas profile picture"
								style={profileContainer}
								src={SaraPic}
							/>
						</Grid>
						<Grid item xs={12} md={6} style={{ paddingLeft: '36px', paddingRight: '36px'}}>
							<Typography style={stepsTitle}>Sara Rojas González</Typography>
							<Typography style={stepsText}>Máster en Marketing Digital, Account Manager en Digital Planning y co-fundadora de Premium & Premium.</Typography>
							<br/>
							<Typography style={stepsText}>Puedes seguirme en:
								<a href='https://www.linkedin.com/in/srojasgonzalez/'>
									<IconButton aria-label="linkedin" size="large">
										<LinkedIn fontSize="inherit" style={{fill: '#000'}}/>
									</IconButton>
								</a>
							</Typography>
						</Grid>
					</Grid>
				</Box>
			</Grid>
			<Grid item xs={12}>
				<Box style={white}>
					<Grid style={stepsContainer} container spacing={2}>
						<Grid item xs={12} md={6} style={{ paddingLeft: '36px', paddingRight: '36px'}}>
							<Typography style={stepsTitle}>Samuel Lapuente Jiménez</Typography>
							<Typography style={stepsText}>Analista programador en GFT. Co-fundador de Premium&Premium. Seriéfilo y fan del diseño de interiores. Desarrollo y diseño de videojuegos. En ocasiones hablo con chatbots y domotizo cosas.</Typography>
							<br/>
							<Typography style={stepsText}>Puedes seguirme en:
								<a href='https://www.linkedin.com/in/samuel-lapuente-jimenez/'>
									<IconButton aria-label="linkedin" size="large">
										<LinkedIn fontSize="inherit" style={{fill: '#000'}}/>
									</IconButton>
								</a>
							</Typography>
						</Grid>
						<Grid item xs={12} md={6} textAlign={'center'} style={{ paddingLeft: '36px', paddingRight: '36px', maxHeight: '350px'}}>
							<img
								alt="Samuel Lapuente profile picture"
								style={profileContainer}
								src={SamuPic}
							/>
						</Grid>
					</Grid>
				</Box>
			</Grid>
			<Grid item xs={12}>
				<Box style={lightBlue}>
					<Grid style={stepsContainer} container spacing={2}>
						<Grid item xs={12} md={6} textAlign={'center'} style={{ paddingLeft: '36px', paddingRight: '36px', maxHeight: '350px'}}>
							<img
								alt="Álvaro Lázaro profile picture"
								style={profileContainer}
								src={AlvaroPic}
							/>
						</Grid>
						<Grid item xs={12} md={6} style={{ paddingLeft: '36px', paddingRight: '36px'}}>
							<Typography style={stepsTitle}>Álvaro Lázaro Sevilla</Typography>
							<Typography style={stepsText}>Analista y desarrollador en SICE. Co-fundador de Premium&Premium. 3D hobbyist y gamedev. Miembro y colaborador habitual con asociaciones de vecinos y comisiones de festejos en Segovia.</Typography>
							<br/>
							<Typography style={stepsText}>Puedes seguirme en:
								<a href='https://www.linkedin.com/in/%C3%A1lvaro-l%C3%A1zaro-7a2772135/'>
									<IconButton aria-label="linkedin" size="large">
										<LinkedIn fontSize="inherit" style={{fill: '#000'}}/>
									</IconButton>
								</a>
							</Typography>
						</Grid>
					</Grid>
				</Box>
			</Grid>
			<Grid item xs={12}>
				<Box style={lightGray}>
					<Grid style={stepsContainer} container spacing={2}>
						<Grid item xs={12} md={6} style={{ paddingLeft: '36px', paddingRight: '36px'}}>
							<Typography style={stepsTitle}>Julian García Ruiz</Typography>
							<Typography style={stepsText}>Diseñador Industrial, Desing Manager y Product Manager en Volvo. Antiguo Design Lead en BBVA Global y España y miembro del equipo de Premium & Premium.</Typography>
							<br/>
							<Typography style={stepsText}>Puedes seguirme en:
								<a href='https://www.linkedin.com/in/julian-g-ruiz/'>
									<IconButton aria-label="linkedin" size="large">
										<LinkedIn fontSize="inherit" style={{fill: '#000'}}/>
									</IconButton>
								</a>
							</Typography>
						</Grid>
						<Grid item xs={12} md={6} textAlign={'center'} style={{ paddingLeft: '36px', paddingRight: '36px', maxHeight: '350px'}}>
							<img
								alt="Julián G. Ruiz profile picture"
								style={profileContainer}
								src={JulianPic}
							/>
						</Grid>
					</Grid>
				</Box>
			</Grid>
			<Grid item xs={12}>
				<Box style={white}>
					<Grid style={stepsContainer} container spacing={2}>
						<Grid item xs={12} md={6} textAlign={'center'} style={{ paddingLeft: '36px', paddingRight: '36px', maxHeight: '350px'}}>
							<img
								alt="David Pascual-Ezama profile picture"
								style={profileContainer}
								src={DavidPic}
							/>
						</Grid>
						<Grid item xs={12} md={6} style={{ paddingLeft: '36px', paddingRight: '36px'}}>
							<Typography style={stepsTitle}>David Pascual-Ezama</Typography>
							<Typography style={stepsText}>Es Doctor en Economía y Psicología, Profesor de la Universidad Complutense de Madrid, investigador en MIT y Harvard University y co-fundador de Premium & Premium.</Typography>
							<br/>
							<Typography style={stepsText}>Puedes seguirme en:
								<a href='https://www.linkedin.com/in/david-pascual-ezama-67801442/'>
									<IconButton aria-label="linkedin" size="large">
										<LinkedIn fontSize="inherit" style={{fill: '#000'}}/>
									</IconButton>
								</a>
							</Typography>
						</Grid>
					</Grid>
				</Box>
			</Grid>
			<Grid item xs={12}>
				<Box style={lightBlue}>
					<Grid style={stepsContainer} container spacing={2}>
						<Grid item xs={12} md={6} style={{ paddingLeft: '36px', paddingRight: '36px'}}>
							<Typography style={stepsTitle}>Elena Jambrina Prieto</Typography>
							<Typography style={stepsText}>Máster en dirección de Fundraising público-privado para entidades no lucrativas, co-fundadora de la propuesta “voy fácil” y miembro del equipo de Premium & Premium.</Typography>
							<br/>
							<Typography style={stepsText}>Puedes seguirme en:
								<a href='https://www.linkedin.com/in/elenajambrinafundraising/'>
									<IconButton aria-label="linkedin" size="large">
										<LinkedIn fontSize="inherit" style={{fill: '#000'}}/>
									</IconButton>
								</a>
							</Typography>
						</Grid>
						<Grid item xs={12} md={6} textAlign={'center'} style={{ paddingLeft: '36px', paddingRight: '36px', maxHeight: '350px'}}>
							<img
								alt="Elena Jambrina profile picture"
								style={profileContainer}
								src={ElenaPic}
							/>
						</Grid>
					</Grid>
				</Box>
			</Grid>
			<MediaQuery maxWidth={1224} onChange={handleMediaQueryChange}>
				<PapFooter
					isMobile={true}
				/>
			</MediaQuery>
			<MediaQuery minWidth={1224} onChange={handleMediaQueryChange}>
				<PapFooter
					isMobile={false}
				/>
			</MediaQuery>
		</Grid>
	)
}